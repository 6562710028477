/* Main CSS file for IRAS inside */
:root {
  --config-color: #d52229;
}

.map-container {
  height: 400px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact-show {
  padding: 2px 28px 2px 8px;
  visibility: hidden;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  visibility: hidden;
}

.register-public-user > div > label {
  font-size: inherit;
  font-weight: 400;
}

.register-public-user > div > div > .ant-select > .ant-select-selector {
  height: 52px !important;
  font-size: 14px;
  align-items: center;
}

.dashboard-filter > div > label {
  font-size: 12px;
  font-weight: 600;
}
.dashboard-filter > div {
  margin-bottom: 0 !important;
}

.dashboard-filter > div .ant-select-selector {
  font-size: 14px !important;
  height: 33px !important;
  border-radius: 6px !important;
}

.dashboard-filter > div .ant-select-selection-search-input {
  height: 30px !important;
}
.dashboard-filter > div .ant-select-selection-item {
  line-height: 35px !important;
}

.filter-row-grid{
  width: 100%;
  display: grid;
  grid-template-columns: calc(33.3% - 7px) calc(33.3% - 7px) calc(33.3% - 7px);
  gap: 10px;
  @media (max-width: 990px) {
    grid-template-columns: 48% 48%;
  }
}
.filter-row-flex{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 10px;
  @media (max-width: 990px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 440px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 320px) {
    grid-template-columns: 1fr;
  }
}
/* .filter-row-flex > * {
  min-width: 100px;
} */

/* CUSTOM SCROLLBAR */

/* Hide scrollbar for Chrome, Safari and Opera */
.overflow::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.overflow {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.lst .col-sm-3 .form-group {
  margin-bottom: 0;
}

.col-25 {
  min-width: 200px;
}

.gYhPik .MuiPaper-root {
  min-width: 60% !important;
  min-height: 70% !important;
  max-height: 90% !important;
  max-width: 90% !important;
  border-radius: 15px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}

.noWrap {
  white-space: nowrap;
}

.alignCenter {
  align-items: center;
}

.bold {
  font-weight: bold;
}
